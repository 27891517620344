<template>
  <div class="addAddress absolute">
    <div class="list">
      <div class="item acea-row row-between-wrapper">
        <div style="color: #2291f8">上课人信息</div>
      </div>
      <div class="item acea-row row-between-wrapper">
        <div class="realname">姓名</div>
        <input
          type="text"
          placeholder="请输入姓名"
          v-model="userAddress.realname"
          required
        />
      </div>
      <div class="item acea-row row-between-wrapper">
        <div class="name">手机号码</div>
        <input
          type="text"
          placeholder="请填写手机号码"
          v-model="userAddress.phone"
          required
        />
      </div>
       <div class="item acea-row row-between-wrapper">
        <div class="name">听课地区</div>
        <input
          type="text"
          placeholder="请填写听课地区，如“合肥”"
          v-model="userAddress.site_city"
        />
      </div>
    </div>

    <div class="list" style="margin-top: 10px">
      <div class="item acea-row row-between-wrapper" style="color: #888">
        <div class="name" style="color: #2291f8">抖音下单信息</div>
        <div>
          <mt-switch v-model="infoSwitch">信息同上</mt-switch>
        </div>
      </div>
      <div class="item acea-row row-between-wrapper">
        <div class="name">姓名</div>
        <input
          type="text"
          placeholder="请输入姓名"
          v-model="userAddress.store_realname"
          required
        />
      </div>
      <div class="item acea-row row-between-wrapper">
        <div class="name">手机号</div>
        <input
          type="text"
          placeholder="请输入手机号"
          v-model="userAddress.store_phone"
          required
        />
      </div>
        <div class="item acea-row row-between-wrapper">
            <div class="name">所在地区</div>
            <div
                    class="picker acea-row row-between-wrapper select-value form-control"
            >
                <div class="address">
                    <div slot="right" @click.stop="show2 = true">
                        {{ userAddress.store_district || "请选择收货地址" }}
                    </div>
                    <CitySelect
                            ref="cityselect"
                            v-model="show2"
                            :callback="result2"
                            :items="district"
                            :ready="ready"
                            provance=""
                            city=""
                            area=""
                    ></CitySelect>
                </div>
                <div class="jym-icon-test20"></div>
            </div>
        </div>
        <div class="item acea-row row-between-wrapper">
            <div class="name">详细地址</div>
            <input
                    type="text"
                    placeholder="请填写具体地址"
                    v-model.trim="userAddress.address"
                    required
            />
        </div>
    </div>

    <div class="keepBnt" @click="submit">立即保存</div>
  </div>
</template>
<script type="text/babel">
import { Toast } from "mint-ui";
import { studentCreateDouyin, studentGetAddress } from "@api/user";
import { CitySelect } from "vue-ydui/dist/lib.rem/cityselect";
const CITYLIST = require("@assets/js/city_list");

export default {
  components: {
      CitySelect
  },
  data() {
    return {
      show2: false,
      district: [],
      id: 0,
      type: "",
      userAddress: {
        realname: "",
        phone: "",
        site_city: "",
        store_realname: "",
        store_phone: "",
        store_district:'',
        address:''
      },
      address: {},
      ready: false,
      infoSwitch: false,
      lesson_id: "",
      admin_id: "",
      is_network: "",
      teacher_id: "",
    };
  },
  watch: {
    infoSwitch(val) {
      if (val) {
        this.userAddress.store_realname = this.userAddress.realname;
        this.userAddress.store_phone = this.userAddress.phone;
      }
    },
  },
  mounted: function () {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.lesson_id = this.$route.query.lesson_id
    this.admin_id = this.$route.query.admin_id
    this.is_network = this.$route.query.is_network
    this.teacher_id = this.$route.query.teacher_id
    window.location.href = `/testh5/pages/subcontract/activity/tiktok?admin_id=` + this.admin_id + "&lesson_id=" + this.lesson_id +"&is_network=" +  this.is_network +  "&teacher_id=" +  this.teacher_id
    if(this.id) this.getUserAddress();
    this.getCityList();
    
  },
  methods: {
    getCityList: function () {
      let that = this;
      that.district = CITYLIST;
      that.ready = true;
    },
    submit() {
      let that = this;
      let userAddress = that.userAddress;

      if (!/^[\u4E00-\u9FA5]{2,10}$/.test(userAddress.realname)) {
        Toast({ message: "姓名必填且为2-10位的中文字符" });
        return false;
      } else if (!/^1[3456789]\d{9}$/.test(userAddress.phone)) {
        Toast({ message: "上课人联系电话不正确" });
        return false;
      } else if (!/^[\u4E00-\u9FA5]{2,10}$/.test(userAddress.store_realname) ) {
        Toast({ message: "下单人姓名必填且为2-10位的中文字符" });
        return false;
      } else if (!/^1[3456789]\d{9}$/.test(userAddress.store_phone)) {
        Toast({ message: "下单人手机号不正确" });
        return false;
      } else {
        const params = {
          ...userAddress,
          ...that.$route.query
        };
        studentCreateDouyin(params)
          .then((res) => {
            let url = that.type == 1 ? '/user/networkStudentList' : '/user'
            that.$dialog.success(res.msg);
            that.$router.push(url)
          })
          .catch((err) => {
            that.$dialog.error(err.msg);
          });
      }
    },

    getUserAddress() {
      let that = this;
      studentGetAddress()
        .then((res) => {
          that.userAddress = res.data;
        })
        .catch((err) => {
          that.$dialog.error(err.msg);
        });
    },
    result2(ret) {
          this.userAddress.store_district =
              ret.itemName1 + " " + ret.itemName2 + " " + ret.itemName3;
          this.address.province = ret.itemName1;
          this.address.city = ret.itemName2;
          this.address.city_id = ret.itemValue2;
          this.address.district = ret.itemName3;
          console.log('我是',ret)
    },
  },
};
</script>
